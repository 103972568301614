import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Message from 'components/content/Message';
export const _frontmatter = {
  "path": "/developer/es6-iterators-for-of",
  "date": "2015-09-08",
  "title": "ES6 ITERATORS & FOR..OF",
  "author": "admin",
  "tags": ["development", "javascript"],
  "featuredImage": "feature.jpg",
  "excerpt": "To loop over an array or a collection JavaScript has been traditionally using for loops. ES5 introduced the for ..each loop. But ES6 introduces a little shorter version, a new interface for iteration called Iterable."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Let's look at the traditional `}<strong parentName="p">{`for`}</strong>{` loop:`}</p>
    <h2>{`for loop`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var items = ['item1', 'item2', 'item3'];

for (var i = 0; i < items.length; i++) {
  console.log(items[i]);
}
`}</code></pre>
    <h2>{`forEach loop`}</h2>
    <p>{`With `}<inlineCode parentName="p">{`ES5`}</inlineCode>{`, you can use the built-in `}<strong parentName="p">{`forEach`}</strong>{` method:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var items = ['item1', 'item2', 'item3'];

myArray.forEach(function (item) {
  console.log(item);
});
`}</code></pre>
    <p>{`This is shorter than the `}<strong parentName="p">{`for`}</strong>{` loop but still you can't break out of the loop using the `}<strong parentName="p">{`break`}</strong>{` statement.`}</p>
    <Message type="info" title="For..in" content="For..in loop iterates through the properties of an object so this is not the ideal solution." mdxType="Message" />
    <h2>{`for..of loop`}</h2>
    <p>{`ES6 introduces `}<inlineCode parentName="p">{`for..of`}</inlineCode>{` loop.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`  var items = ['item1', 'item2', 'item3'];

  for (var item of items) {
    console.log(item);
  }
`}</code></pre>
    <p>{`Let's look at why it is better than its predecessors:`}</p>
    <ul>
      <li parentName="ul">{`It avoids the pitfalls of `}<strong parentName="li">{`for..in`}</strong></li>
      <li parentName="ul">{`Unlike `}<strong parentName="li">{`forEach()`}</strong>{`, it works with `}<inlineCode parentName="li">{`break`}</inlineCode>{`, `}<inlineCode parentName="li">{`continue`}</inlineCode>{`, and `}<inlineCode parentName="li">{`return`}</inlineCode></li>
    </ul>
    <Message type="info" title="for..in vs for..of" content="The for–in loop is for looping over `object properties`.
  The for–of loop is for looping over `data` and array-like objects." mdxType="Message" />
    <h2>{`Collections support for for..in loop`}</h2>
    <p><inlineCode parentName="p">{`for..in`}</inlineCode>{` works for other collections apart from the arrays as well.`}</p>
    <h3>{`Map and Set`}</h3>
    <p><inlineCode parentName="p">{`for..in`}</inlineCode>{` works for `}<strong parentName="p">{`Map`}</strong>{` & `}<inlineCode parentName="p">{`Set`}</inlineCode>{` objects of ES6. A `}<inlineCode parentName="p">{`Set`}</inlineCode>{` object can be used to eliminate duplicates:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`  var uniqueWords = new Set(words);

  for (var word of uniqueWords) {
    console.log(word);
  }
`}</code></pre>
    <p>{`The data inside `}<inlineCode parentName="p">{`Map`}</inlineCode>{` is made of key-value pairs. You can use `}<inlineCode parentName="p">{`destructuring`}</inlineCode>{` to unpack these pairs into seprate variables:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`  var uniqueWords = new Set(words);

  for (var word of uniqueWords) {
    console.log(word);
  }
`}</code></pre>
    <Message type="warn" title="Objects" content="`for..of` does not work with plain old Objects, but if you want to iterate over an object’s properties you can either use `for..in` or the built-in `Object.keys()`." mdxType="Message" />
    <h2>{`Iterators`}</h2>
    <p>{`Iterator enables JavaScript objects to define custom iteration like CLR IEnumerable or Java Iterable.`}</p>
    <p>{`This will generalize `}<inlineCode parentName="p">{`for..in`}</inlineCode>{` to custom iterator-based iteration with `}<inlineCode parentName="p">{`for..of`}</inlineCode>{`.`}</p>
    <Message type="info" title="@@iterator " content="In order to be iterable, an object must implement the `@@iterator` method, meaning that the object (or one of the objects up its prototype chain) must have a property with a **Symbol.iterator**." mdxType="Message" />
    <p>{`Let's look at an example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var foo = "foo",
iter1, iter2, iter3, iter4,
iterator;

iterator = foo[Symbol.iterator]();

iter1 = iterator.next();
iter2 = iterator.next();
iter3 = iterator.next();
iter4 = iterator.next();

console.log(iter1); // done: false, value: "f"
console.log(iter2); // done: false, value: "o"
console.log(iter3); // done: false, value: "o"
console.log(iter4); // done: true,value: undefined
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      